<template>
  <ImageText v-if="value.length" :value="value" theme="Sections" />
</template>

<script>
import ImageText from '@kvass/template-image-text'
import { GetCustomField } from '@/utils'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Project', ['item']),

    value() {
      return GetCustomField('additional-sections', this.item.customFields) || []
    },
  },
  components: {
    ImageText,
  },
}
</script>
