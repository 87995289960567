<template>
  <div v-if="salesman.length" class="roles">
    <div class="roles__title" v-html="intro"></div>

    <div class="roles__list">
      <div class="roles__item" v-for="(person, index) in salesman" :key="index">
        <div class="roles__avatar" :style="{ backgroundImage: `url(${person.avatar})` }"></div>
        <div class="roles__content">
          <div class="roles__name">{{ person.name }}</div>
          <div class="roles__contact">
            <a v-if="person.email" class="roles__email" :href="`mailto:${person.email}`">
              <FontAwesomeIcon :icon="['fal', 'envelope']" />{{ $t('sendEmail') }}
            </a>
            <a v-if="person.phone" class="roles__phone" :href="`tel:${person.phone}`">
              <FontAwesomeIcon :icon="['fal', 'mobile']" />{{ person.phone }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetCustomField } from '@/utils'
export default {
  props: {
    item: Object,
  },
  computed: {
    salesman() {
      return this.$path('item.roles.salesman') || []
    },

    intro() {
      return GetCustomField('contact-intro', this.item.customFields)
    },
  },
}
</script>

<style lang="scss">
.roles {
  a {
    color: var(--body-color);
  }
  &__list {
    display: grid;
    gap: clamp(1rem, 2vw, 2rem);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  &__title {
    font-size: clamp(1.2rem, 3vw, 2rem);

    @include respond-above('phone') {
      text-align: center;
    }
  }

  &__avatar {
    height: 175px;
    aspect-ratio: 1;
    border-radius: 40rem;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;

    @include respond-above('phone') {
      margin-bottom: 1.5rem;
    }

    @include respond-below('phone') {
      height: 100px;
      margin-right: 1.5rem;
    }
  }

  &__subtitle {
    color: rgba($dark, 0.6);
  }

  &__item {
    display: flex;
    align-items: center;
    border-radius: $border-radius;

    @include respond-above('phone') {
      flex-direction: column;
    }
  }

  &__name {
    text-align: left;
    font-weight: bold;
    margin-bottom: 0.25rem;
    font-size: clamp(1rem, 3vw, 2rem);
  }

  &__contact {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    @include respond-above('phone') {
      justify-content: center;
    }
  }

  &__content {
    display: inherit;
    flex-direction: inherit;
    align-items: inherit;
  }

  &__email,
  &__phone {
    opacity: 0.6;
    text-decoration: none;
    white-space: nowrap;

    svg {
      margin-right: 7px;
    }
  }
}
</style>
