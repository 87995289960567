<template>
  <Loader :value="promises.fetch">
    <div class="project-page">
      <section v-if="$path('flatfinders.length', item)" class="section section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" />
        <Flatfinder id="flatfinder" />
      </section>
      <section class="section section__info">
        <div class="container">
          <Info class="grow-1" />
        </div>
      </section>
      <section v-if="additionalSections.hasItems" class="section section__additional-sections">
        <ScrollAnchor value="project-additional-sections" offset="-8rem" :delay="750" />
        <div
          :class="[
            { container: additionalSections.size !== 'full' },
            `project-additional-sections--${additionalSections.size}`,
          ]"
        >
          <AdditionalSections />
        </div>
      </section>
      <section v-if="showStages" class="section section__stages">
        <div class="container">
          <StagesPreview />
        </div>
      </section>
      <section v-if="showResidentials" class="section">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section__lead">
        <div class="container">
          <ScrollAnchor value="lead" offset="-8rem" />
          <Lead />
        </div>
      </section>
      <section class="section" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>
      <section v-if="$path('media.gallery.length', item)" class="section section--no-pb">
        <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
        <Masonry />
      </section>

      <section class="section">
        <ScrollAnchor value="openhouse" offset="-8rem" />
        <div class="container col g-3">
          <Roles />
          <Openhouse v-if="showOpenhouse" />
        </div>
      </section>

      <section class="section section--no-p">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <Map />
      </section>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import ScrollAnchor from '@kvass/scroll-anchor'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return GetCustomField('residential-templates', this.item.customFields)
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    additionalSections() {
      return {
        size: this.$path('additional-sections-settings.size', this.item.customFields) || 'medium',
        hasItems: this.$path('additional-sections.0.image', this.item.customFields),
      }
    },
    showStages() {
      return this.$path('stages.0.image-stage-preview', this.item.customFields)
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return this.$path('residential-templates.references.length', this.item.customFields)
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .section {
    &--no-pb {
      padding-bottom: 0;
    }

    &--no-p {
      padding-top: 0;
      padding-bottom: 0;
    }

    &__additional-sections {
      .project-additional-sections {
        $textOverMargin: 11rem;
        &--full {
          $maxWidth: 700px;
          .kvass-image-text__item:not(.kvass-image-text__item--text-over) {
            .kvass-image-text__content {
              max-width: $maxWidth;
            }
          }
          .kvass-image-text__item--text-over {
            .kvass-image-text__content {
              max-width: calc(#{$maxWidth} + #{$textOverMargin});
            }
          }
        }
      }

      @include respond-below('phone') {
        .container {
          padding: 0;
        }
      }
    }
    &__stages {
      @include respond-below('phone') {
        .container {
          padding: 0;
        }
      }
    }

    &__lead {
      background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.05);
    }

    &__info {
      padding: 4rem 0;
    }
  }
}
</style>
