<template>
  <div class="openhouse">
    <h2 class="openhouse__title">{{ $tc('openhouse', 2) }}</h2>
    <div class="openhouse__list" :class="{ 'openhouse__list--scroll': value.length > 4 }">
      <div class="openhouse__item" v-for="item in value" :key="item.id">
        <FontAwesomeIcon :icon="['fal', 'calendar']" size="lg" />
        <div>
          <div class="openhouse__content row wrap">
            <strong class="openhouse__date">{{ item.from | Moment('dddd D. MMMM') }}</strong>
            <span class="openhouse__time">
              {{ $t('clockAt') }} {{ item.from | Moment('HH.mm') }} -
              {{ item.to | Moment('HH.mm') }}
            </span>
          </div>
          <small v-if="$path('references.length', item)" class="openhouse__references">
            {{
              $t('openhouseAtValue', {
                value: `${$tc(
                  'residential',
                  item.references.length,
                ).toLowerCase()} ${item.references.map(r => r.number).join(', ')}`,
              })
            }}
          </small>
          <ReadMore v-if="item.message" :value="item.message" class="openhouse__message" />
        </div>
      </div>
      <div v-if="!value || !value.length" class="openhouse__fallback openhouse__item">
        {{ $t('openhouseOnRequest') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Moment } from '@/filters'
import ReadMore from '@/components/ReadMore'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    Moment,
  },
  components: {
    ReadMore,
  },
}
</script>

<style lang="scss">
.openhouse {
  &__title {
    margin-bottom: 1.5rem;
  }

  &__list {
    color: $dark;
    &--scroll {
      max-height: 320px;
      overflow-y: scroll;
      @include scrollShadow();
    }

    display: grid;
    grid-gap: 1rem;
  }

  &__item {
    padding: 1.5rem;
    border-left: 3px solid $border-color;
    background: linear-gradient(0.25turn, $light-grey, white);

    border-radius: $border-radius;

    display: flex;
    align-items: flex-start;

    svg {
      margin-right: 1rem;
      opacity: 0.5;
    }
  }

  &__content {
    gap: 0.2rem;
  }

  &__date {
    font-weight: bold;
  }

  &__time {
    opacity: 0.5;
    margin-block: 0.3rem;
  }

  &__date,
  &__time {
    font-size: 1.1em;
  }

  &__message,
  &__references {
    margin: 0;
  }

  &__references {
    opacity: 0.5;
    letter-spacing: 0.5px;
    display: inline-block;
    border-bottom: 1px solid currentColor;
    margin-bottom: 0.6rem;
  }
}
</style>
