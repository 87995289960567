<template>
  <div class="project-hero">
    <div
      class="project-hero__overlay"
      :class="'project-hero__overlay-placement--' + (summary.placement || 'center')"
    >
      <h1 v-if="summary.title" class="project-hero__overlay-title">{{ summary.title }}</h1>
      <div
        v-if="summary.content"
        class="project-hero__overlay-summary"
        v-html="summary.content"
      ></div>
      <div class="row wrap project-hero__overlay-actions">
        <template v-for="(button, index) in buttons">
          <BrochureComponent
            v-if="button.type === 'brochure'"
            :value="brochure"
            class="residential-info__brochure"
            :key="index + 'brochure'"
            :label="button.content"
          />

          <ButtonComponent
            v-else
            :label="button.content"
            :icon="button.icon"
            tag="router-link"
            :to="`#${button.type}`"
            theme="secondary"
            :key="index"
          />
        </template>
      </div>
    </div>
    <div class="project-hero__slide">
      <Slider
        v-if="items.length"
        :value="items"
        :display-thumbnail="true"
        :manual-navigation="true"
      />

      <div class="project-hero__arrow" @click="gotoInfo">
        <FontAwesomeIcon :icon="['far', 'arrow-down']" size="2x" />
      </div>
    </div>

    <div class="project-hero__content">
      <div class="project-hero__stats">
        <template v-for="({ key, label }, index) in enabledStatus">
          <div :key="index" class="project-hero__stats-item">
            <div class="project-hero__stats-value">{{ getStat(key) }}</div>
            <div class="project-hero__stats-label">
              {{ $t(label || `residentialStatus.${key}`) }}
            </div>
          </div>
        </template>
        <div
          v-if="item.id && !stats.total"
          class="project-hero__stats-item project-hero__stats-item--coming"
        >
          <div class="project-hero__stats-value">{{ $t('residentialStatus.upcoming') }}</div>
          <template v-if="hasStats"> {{ stats }}</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScrollIntoView } from '@/utils'
import { GetCustomField } from '@/utils'
import Slider from '@kvass/media-render/Slider'
import BrochureComponent from '@/components/Brochure'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    brochure() {
      return this.$path('item.media.brochure') || []
    },
    enabledStatus() {
      return [
        { key: 'sale' },
        { key: ['sold', 'reserved'], label: 'residentialStatus.sold' },
        { key: 'upcoming' },
      ].filter(e => this.getStat(e.key))
    },
    stats() {
      return this.$path('item.stats') || {}
    },

    hasStats() {
      return this.stats.total
    },

    summary() {
      const defaultData = {
        placement: 'center',
        title: this.$path('item.name'),
        content: this.$tc('getInTouch'),
      }

      if (!this.item) return { placement: 'none' }

      let data = GetCustomField('project-hero', this.item.customFields)

      return Object.entries(data || defaultData).reduce((res, [key, value]) => {
        res[key] = value || defaultData[key]
        return res
      }, {})
    },
    buttons() {
      if (!this.item.customFields) return []
      let items = GetCustomField('project-hero-buttons', this.item.customFields) || []
      if (!items.length || !items.some(i => i.type))
        items = [
          { content: 'Kontakt oss', type: 'lead' },
          { content: 'Finn din bolig', type: 'flatfinder' },
        ]
      return items
        .filter(i => i.type)
        .map(i => {
          return {
            ...i,
            icon: i.type === 'flatfinder' ? ['fal', 'map-pin'] : ['far', 'arrow-down'],
          }
        })
    },
  },
  methods: {
    gotoInfo() {
      ScrollIntoView('.section__info', -document.querySelector('.nav').clientHeight)
    },
    getStat(key) {
      let keys = key instanceof Array ? key : [key]
      return keys.reduce((r, c) => r + this.stats[c], 0)
    },
    onScroll() {
      requestAnimationFrame(() => {
        const scrollTop = window.scrollY / 8
        document.body.style.setProperty('--parallax-top', `${scrollTop}px`)
      })
    },
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  metaInfo() {
    return {
      link: [...this.items.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.project-hero {
  $height: 100vh;

  position: relative;
  height: $height;
  width: 100%;
  background-color: css-alpha('primary', 0.1);

  display: flex;
  flex-direction: column;

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 500ms;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  &__overlay {
    background: radial-gradient(rgba(black, 0.5) 0%, transparent 75%);
    color: white;

    position: absolute;
    z-index: 1;
    padding: 8rem 0;
    font-size: 1.5rem;

    width: 100%;
    max-width: 600px;

    &-placement {
      &--center {
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &--left {
        top: 50%;
        left: 20%;
        transform: translate(-20%, -50%);
        .project-hero__overlay-actions {
          justify-content: start;
        }
      }

      h2 {
        margin-bottom: 0.5rem;
        & + p {
          margin-top: 0;
        }
      }
    }

    &-actions {
      justify-content: center;
      gap: 1rem;
      font-size: 0.7em;
      margin-top: 1.5rem;

      @include respond-below('phone') {
        gap: 1rem 0rem;
        @include gap(1rem);
        margin-top: 1rem;
        & > * {
          margin-left: 2rem;
          margin-bottom: 0rem;
        }
      }
    }

    @include respond-below('phone') {
      padding: 3rem 1rem;
      font-size: 1rem;
    }
  }

  &__content {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    // z-index: 1;
    // overflow: hidden;

    @include respond-below('phone') {
      position: static;
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;

    @include gap(1rem);

    @include respond-below('phone') {
      flex-direction: row !important;
      width: 100%;
      @include gap(0);

      &-item {
        width: auto;
        border-radius: 0 !important;
        flex-grow: 1;

        @for $n from 2 to 4 {
          &:nth-child(#{$n}) {
            background-color: hsl(
              var(--primary-h),
              var(--primary-s),
              calc(var(--primary-l) + #{$n + 1 + '%'})
            );
          }
        }
      }
    }

    @include respond-below('tablet') {
      flex-direction: column;
    }

    &-value {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 0.5rem;

      @include respond-below('phone') {
        font-size: 1.25em;
      }
    }

    &-label {
      color: rgba(white, 0.6);
      font-size: 0.8em;

      @include respond-below('phone') {
        font-size: 0.7em;
      }
    }

    &-item {
      background-color: var(--primary);
      border-radius: var(--border-radius);
      color: white;
      box-shadow: 0 10px 15px -10px rgba(black, 0.05);
      line-height: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0.5rem;
      width: 130px;

      @include respond-below('tablet') {
        width: 110px;
        height: auto;
        padding: 1rem 0.5rem;
      }

      &--coming {
        width: initial;
        padding: 1.5rem;
        font-size: 0.7em;

        & > * {
          margin: 0;
        }
      }
    }
  }

  &__slide {
    position: relative;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    filter: brightness(0.75);

    iframe {
      min-height: $height;
    }

    .kvass-media-render-slider {
      &__navigation {
        @include respond-below('phone') {
          display: none;
        }
      }

      .kvass-media-render-image {
        top: var(--parallax-top, 0);
      }
    }
  }

  &__arrow {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    padding: 2rem;
    z-index: 1;
    border-radius: 50%;
    color: white;
    background: radial-gradient(black 0%, transparent 50%);
    transition: opacity 150ms ease-out;
    transform: translateX(-50%);

    #app.page--is-scrolled & {
      opacity: 0;
    }

    svg {
      $movement: 5px;
      @keyframes point {
        0% {
          transform: translateY(-$movement);
        }
        50% {
          transform: translateY($movement);
        }
        100% {
          transform: translateY(-$movement);
        }
      }

      animation: point 2s infinite;
    }
  }
}
</style>
