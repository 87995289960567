import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Loading from '@/views/Loading.vue'
import Project from '@/views/Project.vue'

Vue.use(VueMeta)
Vue.use(VueRouter)

function getRoutes() {
  return [
    {
      path: '/',
      name: 'Project',
      component: Project,
    },
    {
      path: '/residential/:id',
      name: 'Residential',
      props: true,
      beforeEnter: (to, from) => {
        window.open(`${window.location.origin}/api/residential/${to.params.id}/shop`)
        return
      },
    },
    // {
    //   path: '/residential/:id',
    //   name: 'Residential',
    //   props: true,
    //   beforeEnter: (to, from, next) => {
    //     if (!from.name) return next()
    //     return window.open(`${window.location.origin}${to.fullPath}`)
    //   },
    //   component: () =>
    //     LazyLoadView(import(/* webpackChunkName: "residential" */ '@/views/Residential.vue')),
    // },
    {
      path: '*',
      redirect: {
        name: 'Project',
      },
    },
  ]
}

const routes = getRoutes()

function LazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: Loading,
    delay: 150,
    // error: require('@views/_timeout.vue').default,
    // timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  if (from.name === 'Residential' && to.name === 'Project')
    window.sessionStorage.removeItem('flatfinder_current')

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next(Object.assign({}, to, { query: from.query }))
  } else {
    next()
  }
})

export default router
